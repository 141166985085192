﻿.header-media {
	position: relative;
	z-index: 0;
	height: rem(668px);
	overflow: hidden;

	iframe {
		position: absolute;
		z-index: -1;
		width: rem(1440px);
		height: rem(810px);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;

		@include breakpoint(ll) {
			width: rem(1920px);
			height: rem(1080px);
		}

		@include breakpoint(d) {
			width: rem(2560px);
			height: rem(1440px);
		}
	}

	img {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&:before {
		position: absolute;
		content: '';
		z-index: 1;
		width: 100%;
		height: rem(120px);
		top: 0;
		left: 0;
		background: linear-gradient(0deg, rgba(0,25,50,0) 0%, rgba(0,25,50,1) 100%);
		opacity: 0.6;

		.home & {
			display: none;
		}
	}
}