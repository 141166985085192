@font-face {
	font-family: 'Open Sans';
	src: url('../../fonts/OpenSans-Regular.woff2') format('woff2'), url('../../fonts/OpenSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../../fonts/OpenSans-SemiBold.woff2') format('woff2'), url('../../fonts/OpenSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../../fonts/OpenSans-Bold.woff2') format('woff2'), url('../../fonts/OpenSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../../fonts/OpenSans-ExtraBold.woff2') format('woff2'), url('../../fonts/OpenSans-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(18px);
	line-height: rem(32px);

	@media only screen and (min-width: 432px) {
		font-size: rem(17px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(16px);
	}
}

@mixin font-legal {
	font-size: rem(13px);
	line-height: 1.2em;
}

@mixin font-label {
	font-size: rem(14px);
	line-height: rem(22px);
}

@mixin font-size-button {
	font-size: rem(16px);
	line-height: 1.2em;
}

@mixin font-size-title-small {
	font-size: rem(22px);
	line-height: rem(32px);
}

@mixin font-size-menu {
	font-size: rem(24px);
	line-height: 1.2em;
}

@mixin font-filter-title {
	font-size: rem(26px);
	line-height: rem(32px);
}

@mixin font-size-title {
	font-size: rem(36px);
	line-height: rem(48px);
}

