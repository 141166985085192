﻿@import "fonts";
@import "icon";

$font-text: "Open Sans", sans-serif;

$color-primary: #005dcc;
$color-primary-light: #e5eefa;
$color-primary-dark: #001932;
$color-green: #17c7a0;
$color-green-dark: #0d624f;
$color-neutral: #fff;
$color-grey: #e5eefa;
$color-grey-dark: #8c9fb7;
$color-yellow: #fbbd1a;
$color-border: #e2ecf9;

$color-text: #000;

$transition-time: 0.25s;

$border-radius: rem(5px);