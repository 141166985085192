﻿#side-pop-up {
	display: none;

	&:checked + .side-pop-up-toggler {
		@extend .is-active;
		@extend %hamburger-hover;

		@include breakpoint(l) {
			padding: rem(19px) rem(64px) 0 0;
			box-shadow: none;
			background: #fff;

			.hamburger-label {
				display: none;
			}
		}
	}

	&:checked ~ .side-pop-up {
		transform: translateX(0);
	}
}

.side-pop-up {
	position: fixed;
	display: flex;
	flex-wrap: wrap;
	z-index: 1000;
	width: 100%;
	height: 100vh;
	top: 0;
	right: 0;
	transform: translateX(100%);
	color: #fff;
	transition: all $transition-time ease-in-out;
	overflow: hidden;
	background: $color-primary;

	.container {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.side-pop-up__nav {
		width: 100%;
		padding: rem(156px) 0;

		@include breakpoint(l) {
			padding: rem(96px) 0;
			max-width: rem(480px);
		}

		@include breakpoint(lm) {
			padding: rem(126px) 0;
			max-width: rem(520px);
		}

		@include breakpoint(ll) {
			padding: rem(156px) 0;
			max-width: rem(640px);
		}
	}

	.languages {
		@extend %languages-side-menu;
	}

	.button.is-green {
		width: 100%;
	}

	.side-pop-up__content {
		overflow: hidden;
		z-index: 0;
		display: flex;
		width: 100%;
		padding: rem(29px) 0 rem(24px);
		background: #fff;

		@include breakpoint(l) {
			position: absolute;
			max-width: rem(480px);
			height: 100%;
			padding: rem(119px) 0 rem(44px);
			top: 0;
			right: 0;
		}

		@include breakpoint(lm) {
			max-width: rem(520px);
			padding: rem(149px) 0 rem(74px);
		}

		@include breakpoint(ll) {
			max-width: rem(640px);
			padding: rem(179px) 0 rem(104px);
		}
	}

	.side-pop-up__content-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		max-width: rem(352px);
		padding: 0 rem(20px);
		margin: 0 auto;
	}

	.title {
		@include font-size-title;
		color: $color-primary;
		font-weight: 800;
		margin: 0 0 rem(38px);
	}

	.buttons {
		margin-top: auto;
	}

	.side-pop-up__image {
		position: absolute;
		z-index: -1;
		max-width: rem(640px);
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		display: none;

		@include breakpoint(l) {
			display: block;
		}
	}
}
