﻿.projects {
	padding: rem(104px) 0 rem(80px);

	.project {
		//margin: 0 0 rem(48px);
	}

	.column {
		transition: opacity $transition-time ease;

		&.is-hidden {
			opacity: 0;
		}
	}
}

.project {
	position: relative;
	display: block;

	.button {
		position: absolute;
		z-index: 1;
		width: calc(100% - 80px);
		top: rem(40px);
		left: rem(40px);
		text-align: center;
	}

	.project__status {
		@include font-label;
		position: absolute;
		z-index: 1;
		padding: rem(10px) rem(22px) rem(8px);
		bottom: rem(20px);
		left: 50%;
		transform: translateX(-50%);
		color: $color-primary;
		background: #fff;
		font-weight: 800;
	}

	img {
		transform: scale(1);
		transition: transform 500ms ease;
	}

	&:focus,
	&:hover {

		img {
			transform: scale(1.1);
		}
	}
}