﻿.project-slide {
	position: relative;
	background: $color-primary-dark;

	.image-container {
		width: 100%;
		max-width: 1152px;
		float: right;
	}

	&__content {

		@include breakpoint(l) {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			max-width: calc(50% + 216px);
		}

		.title {
			color: #fff !important;
			font-size: rem(32px);
			line-height: rem(42px);

			@include breakpoint(l) {
				font-size: rem(64px);
				line-height: rem(84px);
			}
		}
	}
}
