﻿.content-block.is-text-slider {

	.swiper-button-next,
	.swiper-button-prev {
		position: relative;
		float: left;
		width: 50%;
		height: rem(44px);
		margin: rem(32px) 0 0;
		top: auto;
		color: rgba($color-primary, 0.1);
		transition: color $transition-time ease-in-out;

		@include breakpoint(ts) {
			position: absolute;
			width: rem(44px);
			height: rem(44px);
			margin: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&:after {
			font-size: rem(24px);
		}

		&:hover {
			color: rgba($color-primary, 0.2);
		}
	}

	.swiper-button-next {

		&:after {
			@include icon($icon-arrow-right)
		}
	}

	.swiper-button-prev {

		&:after {
			@include icon($icon-arrow-left)
		}
	}
}

%white-swiper-buttons {

	.swiper-button-next,
	.swiper-button-prev {
		overflow: hidden;
		width: rem(54px);
		height: rem(56px);
		margin: 0;
		top: 50%;
		transform: translateY(-50%);
		color: $color-primary;
		background: #fff;
		transition: color $transition-time ease-in-out;

		&:before {
			position: absolute;
			z-index: -1;
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0.2;
			background: #000;
			transform: translateX(100%);
			transition: transform 150ms ease-in-out;
		}

		&:after {
			font-size: rem(24px);
		}

		&:hover {

			&:before {
				transform: translateX(0);
			}
		}

		&.swiper-button-disabled {
			display: none;
		}
	}

	.swiper-button-next {
		border-radius: $border-radius 0 0 $border-radius;
		right: 0;
		left: auto;

		&:before {
			transform: translateX(-100%);
		}

		&:after {
			@include icon($icon-arrow-right)
		}

		&:hover {

			&:before {
				transform: translateX(0);
			}
		}
	}

	.swiper-button-prev {
		border-radius: 0 $border-radius $border-radius 0;
		left: 0;

		&:before {
			transform: translateX(100%);
		}

		&:after {
			@include icon($icon-arrow-left)
		}

		&:hover {

			&:before {
				transform: translateX(0);
			}
		}
	}
}

