﻿.form {
	width: 100%;
	overflow: hidden;

	form {
		width: 100%;
		overflow: hidden;
	}

	.label {
		display: block;
		background: none;
		font-weight: 800;

		@include breakpoint(ts) {
			margin: 0 0 rem(2px);
		}
	}

	.input {
		margin: 0 0 rem(3px);
	}

	.control {

		@include breakpoint(ts) {
			width: 100%;
		}
	}

	.field {
		margin: 0 0 rem(3px);
	}

	.consent {
		//margin-top: rem(12px)
	}

	.button {
		margin: rem(32px) 0 rem(64px) auto;

		@include breakpoint(l) {
			float: right;
			margin: 0 0 rem(26px) auto;
		}
	}

	.hidden,
	.email2 {
		display: none;
	}
}
