﻿.employee {

	.image {
		max-width: rem(240px);
		margin: 0 auto rem(30px);
		border-radius: 50%;
	}

	.sub-title {
		color: #fff;
		text-align: center;
	}

	.employee__function {
		display: block;
		text-align: center;
	}
}
