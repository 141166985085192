﻿.filter {
	position: relative;
	z-index: 10;
	display: flex;
	background: $color-grey-dark;
	padding: rem(10px) 0;
	margin: rem(-49px) 0 0;
	color: #fff;

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include breakpoint(ll) {
			flex-wrap: initial;
		}
	}

	h2 {
		@include font-filter-title;
		padding: rem(10px) rem(48px) rem(10px) 0;
	}

	form {
		display: flex;
		flex-wrap: wrap;
	}

	#solutions__checkbox {
		display: none;

		&:checked + .solutions {
			border-radius: $border-radius $border-radius 0 0;

			dt label {
				border: 1px solid rgba($color-grey-dark, 0.2);
				border-bottom: none;

				&:after {
					@include icon($icon-arrow-up);
				}

				&:hover {

					&:after {
						transform: translateY(-20%);
					}
				}
			}

			dd {
				display: block;
				border: 1px solid rgba($color-grey-dark, 0.2);
				border-top: none;
			}
		}
	}

	.solutions {
		color: $color-grey-dark;
		position: relative;
		z-index: 10;
		background: #fff;
		font-weight: 600;
		width: rem(334px);
		border-radius: $border-radius;
		margin: rem(10px) rem(20px) rem(10px) 0;

		input {
			display: none;

			&:checked + label {
				color: $color-primary;
			}
		}

		dt label {
			position: relative;
			padding: rem(11px) rem(63px) rem(11px) rem(25px);
			border: 1px solid transparent;

			&:after {
				@include icon($icon-arrow-down);
				position: absolute;
				top: rem(20px);
				right: rem(24px);
				transition: transform $transition-time ease-in;
			}

			&:hover {

				&:after {
					transform: translateY(20%);
				}
			}
		}

		dd {
			display: none;
			position: absolute;
			width: 100%;
			padding: 0 rem(10px) rem(10px);
			background: #fff;
			border-radius: 0 0 $border-radius $border-radius;

			label {
				display: block;
				padding: 0 rem(16px);
				transition: color $transition-time ease-in;
				cursor: pointer;

				&:hover {
					background: $color-primary-light;
				}
			}
		}
	}

	.project-statuses {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0 rem(10px) 0 rem(-10px);

		input {
			display: none;

			&:checked + label {
				opacity: 1;
				color: $color-primary;
			}
		}

		label {
			color: #7c8da1;
			opacity: 0.3;
			transition: opacity $transition-time ease-in;
			width: rem(175px);
			padding: rem(12px) 0;
			margin: rem(10px);
			text-align: center;

			&:active,
			&:hover {
				opacity: 0.6;
				color: $color-primary;
			}
		}
	}

	.project-statuses label,
	.solutions dt label {
		cursor: pointer;
		display: block;
		background: #fff;
		font-weight: 600;
		border-radius: $border-radius;
	}

	.button {
		margin: rem(10px) 0 rem(10px);

		.js & {
			display: none;
		}
	}

	.filter__reset {
		position: relative;
		display: inline-block;
		padding: rem(12px) 0 rem(12px) rem(22px);
		margin: rem(10px) 0 rem(10px) auto;
		white-space: nowrap;
		transition: color $transition-time ease-in;

		&:before {
			@include icon($icon-close);
			position: absolute;
			font-size: rem(16px);
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}

		&:hover {
			color: $color-primary;
		}
	}
}
