$icon-arrow-down: '\ea01';
$icon-arrow-left: '\ea02';
$icon-arrow-right: '\ea03';
$icon-arrow-up: '\ea04';
$icon-check: '\ea05';
$icon-chevron-down: '\ea06';
$icon-chevron-left: '\ea07';
$icon-chevron-right: '\ea08';
$icon-chevron-up: '\ea09';
$icon-close: '\ea0a';
$icon-external: '\ea0b';
$icon-mail: '\ea0c';
$icon-phone: '\ea0d';
$icon-play: '\ea0e';
$icon-facebook: '\ea0f';
$icon-instagram: '\ea10';
$icon-linkedin: '\ea11';
$icon-pinterest: '\ea12';
$icon-twitter: '\ea13';
$icon-vimeo: '\ea14';
$icon-whatsapp: '\ea15';
$icon-youtube: '\ea16';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-check:before { content: $icon-check; }
.icon-chevron-down:before { content: $icon-chevron-down; }
.icon-chevron-left:before { content: $icon-chevron-left; }
.icon-chevron-right:before { content: $icon-chevron-right; }
.icon-chevron-up:before { content: $icon-chevron-up; }
.icon-close:before { content: $icon-close; }
.icon-external:before { content: $icon-external; }
.icon-mail:before { content: $icon-mail; }
.icon-phone:before { content: $icon-phone; }
.icon-play:before { content: $icon-play; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-twitter:before { content: $icon-twitter; }
.icon-vimeo:before { content: $icon-vimeo; }
.icon-whatsapp:before { content: $icon-whatsapp; }
.icon-youtube:before { content: $icon-youtube; }
