﻿.button {
	@include font-size-button;
	position: relative;
	z-index: 0;
	display: inline-block;
	border: none;
	padding: rem(19px) rem(33px) rem(18px) rem(25px);
	margin: 0;
	text-decoration: none;
	color: #fff;
	overflow: hidden;
	cursor: pointer;
	transition: background $transition-time ease-in-out;
	text-decoration: none;
	background: $color-primary;
	border-radius: $border-radius;
	font-weight: 600;
	overflow: hidden;

	&:before {
		position: absolute;
		z-index: -1;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.2;
		background: #000;
		transform: translateX(-100%);
		transition: transform 150ms ease-in-out;
	}

	&:after {
		@include icon($icon-arrow-right);
		position: relative;
		top: rem(-2px);
		left: rem(8px);
		font-size: rem(16px);
		transition: transform 150ms ease-in-out;
	}

	@at-root %button-is-green {
		background: $color-green;
	}

	&.is-green {
		@extend %button-is-green;
	}

	&.is-white {
		background: #fff;
		color: $color-primary;
	}

	@at-root %button-is-yellow {
		background: $color-yellow;
		color: #000;
	}

	&.is-yellow {
		@extend %button-is-yellow;
	}

	@at-root %button-is-external {

		&:after {
			@include icon($icon-external);
		}
	}

	&.is-external {
		@extend %button-is-external;
	}

	@at-root %button-is-black {
		background: #000;
		color: #fff;

		&:before {
			background: #fff;
		}
	}

	&.is-black {
		@extend %button-is-black;
	}

	&.is-whatsapp,
	&.is-telephone,
	&.is-mail,
	&.is-linkedin {
		padding: rem(19px) rem(25px) rem(18px) rem(50px);

		&:after {
			position: absolute;
			top: 50%;
			right: auto;
			left: rem(24px);
			transform: translateY(-50%);
		}
	}

	&.is-whatsapp {

		&:after {
			@include icon($icon-whatsapp);
		}
	}

	&.is-telephone {

		&:after {
			@include icon($icon-phone);
		}
	}

	&.is-mail {

		&:after {
			@include icon($icon-mail);
		}
	}

	&.is-linkedin {

		&:after {
			@include icon($icon-linkedin);
		}
	}

	&.is-centerd {
		display: block;
		text-align: center;
	}

	&.is-loading {
		padding-left: rem(50px);

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid #fff;
			border-radius: 50%;
			border-top: rem(4px) solid transparent;
			width: rem(20px);
			height: rem(20px);
			top: rem(18px);
			left: rem(16px);
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&:focus {
		@extend %button-hover;
	}

	&:hover {
		@extend %button-hover;
	}
}

%button-hover {

	&:before {
		transform: translateX(0);
	}
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	margin-right: rem(-10px);
	margin-left: rem(-10px);

	.button {
		margin: rem(5px) rem(10px);
	}

	&.is-full-width {
		display: flex;
		flex-direction: column;

		.button {
			display: inline-block;
		}
	}

	&.is-centerd {
		justify-content: center;
	}
}