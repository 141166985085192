﻿.partner-links {
	@include font-legal;
	display: flex;
	align-items: center;

	&.has-background {
		padding: rem(6px) rem(50px);
		background: rgba($color-primary-dark, 0.2);
	}

	dt {
		padding: 0 rem(31px) 0 0;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: rem(9px) 0 rem(11px);
		margin: 0 rem(-16px) 0;
		list-style: none;
		color: #fff;

		a {
			margin: 0 rem(16px);

			&:hover {
				text-decoration: underline;
			}

			&.kerklaan-horti-materials,
			&.mustang-demolition {
				display: block;
				text-indent: -9999px;
				overflow: hidden;
			}

			&.kerklaan-horti-materials {
				width: rem(163px);
				height: rem(32px);
				background: url(../../images/logo-kerklaan-horti-materials.svg);
			}

			&.mustang-demolition {
				width: rem(99px);
				height: rem(40px);
				background: url(../../images/logo-mustang-demolition.svg);
			}
		}
	}
}
