﻿.call-to-action {
	position: relative;
	z-index: 0;
	display: flex;
	align-items: center;
	min-height: rem(480px);
	color: #fff;

	.container {
		padding-top: rem(37px);
		padding-bottom: rem(50px);
	}

	.title {
		max-width: rem(672px);
		margin: 0 0 rem(53px);
	}

	img {
		position: absolute;
		z-index: -3;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(#000, 0.3);
	}
}
