﻿.reference {

	.image {
		max-width: rem(196px);
		margin: 0 auto rem(30px);
		border-radius: 50%;
	}

	.reference__title {
		.sub-title,
		.reference__company {
			@include font-text;
			font-weight: 800;
			display: inline;
		}

		.reference__company {
			color: $color-grey-dark;
		}

		+ .button {
			margin-top: rem(30px);
		}
	}
}