﻿.content-block {
	position: relative;
	padding: rem(89px) 0 rem(75px);
	overflow: hidden;

	.title {
		margin-bottom: rem(27px);

		strong {
			color: $color-grey-dark;

			.is-demolition & {
				color: mix($color-yellow, #000, 75%);
			}
		}
	}

	.content {

		.sub-title + ul {
			margin-top: rem(23px);
		}

		ul {
			margin: 0 0 rem(29px);
		}
	}

	.content-block__content {
		max-width: rem(1032px);
	}

	.buttons {
		margin-top: rem(16px);
		margin-bottom: rem(24px);

		.is-demolition & {

			.button {
				@extend %button-is-yellow;
			}
		}
	}

	&.has-background {
		background: mix(#000, $color-grey, 5%);

		.is-demolition & {
			background: mix(#000, $color-yellow, 5%);
		}
	}

	&.has-dark-background {
		background: $color-primary-dark;
		color: #fff;

		.title {
			color: $color-grey-dark;

			strong {
				color: #fff;
			}
		}

		.is-demolition & {
			background: $color-yellow;
			color: #000;

			.title {
				color: #000;

				strong {
					color: mix(#000, $color-yellow);
				}
			}

			.button {
				@extend %button-is-black;
			}
		}
	}

	&.has-blue-background {
		background: $color-primary url(../../images/background-pattern.png) no-repeat right top;
		color: #fff;

		.title {
			color: #fff;

			strong {
				color: #6ca7ee;
			}
		}

		.content-block__content-container {

			@include breakpoint(l) {
				max-width: rem(480px);
				padding: 0 rem(48px) 0 0;
			}
		}

		.content-block__content {
			padding: 0 rem(42px) 0 0;
		}
	}

	&.has-green-background {
		background: $color-green;
		color: #fff;

		.title {
			color: #fff;

			strong {
				color: $color-green-dark;
			}
		}
	}

	&.has-yellow-background {
		background: $color-yellow;

		.title {

			strong {
				color: mix(#000, $color-yellow);
			}
		}
	}

	&.is-intro {
		background: $color-grey;

		.is-demolition & {
			background: rgba($color-yellow, 0.1);
		}

		.title + .title {
			color: $color-grey-dark;
			margin-top: rem(-27px);

			.is-demolition & {
				color: mix($color-yellow, #000, 75%);
			}
		}

		.buttons {
			margin-top: rem(45px);
			margin-top: rem(35px);
		}

		.sub-title + .social-media {
			margin-top: rem(5px);
		}
	}

	&.is-shortcuts-block {

		.columns {
			margin-top: rem(41px);
			margin-bottom: rem(-19px);
		}
	}

	&.is-default {

		.image {
			margin: rem(55px) 0 rem(24px);
		}

		.buttons {

			.button:first-child {
				@extend %button-is-green;

				.is-demolition & {
					@extend %button-is-yellow;
				}
			}
		}
	}

	&.is-big-image {

		.home & {
			padding: rem(149px) 0 rem(157px);
		}

		.container {

			@include breakpoint(l) {
				display: flex;
			}
		}

		.buttons {

			.button {

				&:first-child {
					@extend %button-is-green;

					.is-demolition & {
						@extend %button-is-yellow;
					}
				}
			}
		}

		.content-block__content-container {

			@include breakpoint(l) {
				max-width: rem(480px);
				padding: 0 rem(48px) 0 0;
			}
		}

		.content-block__content {
			padding: 0 rem(42px) 0 0;
		}

		.content-block__image-container {
			width: 100%;
		}

		.image {
			margin: rem(55px) 0 rem(24px);

			@include breakpoint(l) {
				margin: rem(8px) 0 0;
			}
		}
	}

	&.is-slider-block {
		@extend %swiper-numberd-pagination;

		.swiper-pagination {
			min-height: rem(72px);
			position: relative;
			text-align: left;
			margin-bottom: rem(27px);
		}

		.image {
			margin: rem(30px) 0 rem(30px);

			@include breakpoint(l) {
				margin: 0 0 rem(30px);
			}
		}
	}

	&.is-form-block {

		.container {
			display: flex;
			flex-wrap: wrap;

			@include breakpoint(l) {
			}
		}

		.logo {
			margin-top: auto;
			margin-bottom: auto;
		}

		.content-block__form-container {

			@include breakpoint(l) {
				width: calc(100% - 19.5rem); // 312px
				padding: 0 rem(168px) 0 0;
			}
		}

		.content-block__contact-container {
			position: relative;
			z-index: 0;
			width: 100%;

			@include breakpoint(l) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				max-width: rem(312px);
			}
		}

		.content-block__image {
			position: absolute;
			display: none;
			z-index: -1;
			max-width: rem(640px);
			right: rem(-192px);
			bottom: rem(-75px);

			@include breakpoint(l) {
				display: block;
			}
		}

		.button {

			.is-demolition & {
				@extend %button-is-black;
			}
		}
	}

	&.is-text-slider {
		background: $color-primary-light;

		.is-demolition & {
			background: rgba($color-yellow, 0.1);
		}

		.container {
			display: flex;
			//flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
		}

		.content-block__slider-container {
			position: relative;
			width: 100%;
			max-width: rem(1032px);
			margin: rem(25px) 0;

			@include breakpoint(ts) {
				padding: 0 rem(80px);
			}
		}

		.title {
			margin: 0;
		}

		.button {
			margin: rem(25px) 0;

			.is-demolition & {
				@extend %button-is-yellow;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {

			.is-demolition & {
				color: mix($color-yellow, #000, 75%);
				opacity: 0.5;

				&:hover {
					color: mix($color-yellow, #000, 100%);
				}
			}
		}

		.is-button-slider {
			text-align: right;
		}
	}

	&.is-employees-block {
		@extend %white-swiper-buttons;
		position: relative;

		.title {
			margin: 0 0 rem(70px);
		}
	}

	&.is-references-block {
		padding: rem(96px) 0 0;

		.column {
			margin-bottom: rem(86px);
		}
	}

	&.is-call-to-action-block {
		padding: 0;
		background: $color-grey;

		.title {
			margin: 0 0 rem(53px);
		}

		.button {
			&[target=_blank] {
				@extend %button-is-green;
				@extend %button-is-external;
			}
		}

		.container {
			padding: rem(89px) rem(20px) rem(75px);
		}

		figure {
			width: 100%;
		}

		@include breakpoint(ds) {
			// Vertically align container (because image is absolute, it's not a flex child)
			position: relative;
			display: flex;
			min-height: 720px;
			align-items: center;

			&::before {
				// Ghost element to make section at least the height of the image
				width: 0;
				height: 0;
				padding-bottom: (50% * 1/2);
				content: '';
				float: left;
			}

			.container {
				padding-right: calc(1456px / 2);
			}

			figure {
				right: 0;
			}

			figure {
				position: absolute;
				top: 0;
				right: 0;
				overflow: hidden;
				width: 50%;
				height: 100%;

				@supports (object-fit: cover) {
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
			}
		}
	}

	&.is-solutions-slider {
		@extend %white-swiper-buttons;

		.container > .title {

			@include breakpoint(l) {
				max-width: rem(390px);
			}
		}

		.container.is-flex {

			@include breakpoint(lm) {
				display: flex;
			}

			.content-block__content-container {
				max-width: initial;

				@include breakpoint(lm) {
					width: rem(480px);
					padding: 0 rem(48px) 0 0;
					flex-shrink: 0;
				}
			}
		}

		.content-block__content {
			position: relative;
			z-index: 2;
			padding: rem(53px) rem(42px) 0 0;
			max-width: initial;

			.title {
				display: block;
				margin: 0 0 rem(23px);
			}

			.button {
				margin: 0 0 rem(85px);
			}
		}

		.content-block__image-container {
			position: relative;
			z-index: 0;
			margin-bottom: rem(85px);

			.solutions-slide-obscure {

				@include breakpoint(lm) {
					position: absolute;
					z-index: 1;
					width: rem(500px);
					height: calc(100% + 1px);
					top: 0;
					right: 100%;
					overflow: hidden;

					&:before {
						position: absolute;
						z-index: -1;
						content: '';
						width: calc(100vw - var(--sw));
						height: 100%;
						top: 0;
						left: 0;
						background: $color-primary url(../../images/background-pattern.png) no-repeat right top rem(-89px);
					}
				}

				@include breakpoint(ll) {
					width: calc((100vw - var(--sw) - 87rem) / 2 + 30rem);
				}
			}

			@include breakpoint(lm) {
				width: calc(100% - 30rem);
			}
		}

		.shortcut {
			border: rem(8px) solid #fff;
		}

		.columns,
		.swiper-container {
			overflow: visible;
			z-index: 0;
		}
	}

	&.is-home-projects-block {
		@extend %swiper-flat-pagination;

		.project-slider__extra {
			padding: rem(23px) 0 0;

			@include breakpoint(t) {
				padding: rem(63px) 0 0;
				text-align: right;
			}
		}

		.swiper-pagination {
			position: relative;
			text-align: left;
			margin: 0 0 rem(32px);

			@include breakpoint(t) {
				position: absolute;
				text-align: center;
				margin: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.link {
			position: relative;
			color: $color-grey-dark;
			padding: 0 rem(26px) 0 0;

			&:before {
				@include icon($icon-arrow-right);
				position: absolute;
				font-size: rem(16px);
				top: rem(3px);
				right: 0;
				transition: right $transition-time ease-in-out;
			}

			&:hover {

				&:before {
					right: rem(-4px);
				}
			}
		}
	}

	&.is-album {

		.columns {
			margin-bottom: 1px;
		}
	}

	&.is-project {

		.columns + .buttons {
			margin-top: rem(55px);
		}
	}
}
