﻿.textarea {
	width: 100%;
	min-height: rem(352px);
	padding: rem(10px) rem(20px);
	border: 1px solid transparent;
	-moz-appearance: none;
	-webkit-appearance: none;
	resize: vertical;
	transition: all $transition-time ease-in-out;
	border-radius: $border-radius;

	&:hover,
	&:focus {
		border: 1px solid $color-primary;
		box-shadow: none;
		outline: none;
	}
}
