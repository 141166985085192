﻿.checkbox,
.dataconsent,
.radio {
	position: relative;
	text-align: left;

	input {
		position: absolute;
		margin: 0;
		top: rem(40px);
		left: rem(10px);
		opacity: 0;

		&:focus + label:before {
			box-shadow: 0 0 5px rgba($color-primary, 0.07);
		}
	}

	label {
		position: relative;
		display: block;
		padding: 0 0 0 rem(42px);
		margin: 0;
		cursor: pointer;
		margin: 0 0 rem(7px);

		&:before {
			content: '';
			position: absolute;
			width: rem(24px);
			height: rem(24px);
			border: 1px solid transparent;
			border-radius: $border-radius;
			padding: rem(6px) 0 0;
			left: 0;
			text-align: center;
			background: #fff;
			transition: all $transition-time ease-in-out;
		}

		&:focus,
		&:hover {

			&:before {
				border: 1px solid $color-primary;
			}
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.checkbox,
.dataconsent {

	input {

		&:checked + label {

			&:before {
				@include icon($icon-check);
				color: #000;
			}
		}
	}

	label {

		&:before {
			font-size: rem(10px);
			color: transparent;
		}
	}
}

.radio {

	input {

		&:checked + label {

			&:after {
				opacity: 1;
			}
		}
	}

	label {

		&:before {
			border-radius: 100%;
		}

		&:after {
			position: absolute;
			content: '';
			width: rem(14px);
			height: rem(14px);
			border-radius: 100%;
			top: rem(20px);
			left: rem(3px);
			background: rgba($color-primary, 0.8);
			opacity: 0;
			transition: opacity $transition-time ease-in-out;
		}
	}
}

.checkbox,
.radio {

	label {
		//@include font-title-radio-checkbox;
		//font-weight: 500;

		&:before {
			top: rem(17px);
		}
	}
}


.dataconsent {

	label {

		&:before {
			top: rem(5px);
		}
	}
}

.radiobuttonlist {
	display: flex;
	flex-wrap: wrap;
	margin: 0 rem(-20px);

	.radio {
		margin: 0 rem(20px);
	}
}