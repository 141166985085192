﻿.container {
	width: 100%;
	max-width: rem(1432px);
	padding: 0 rem(20px);
	margin: 0 auto;

	&.is-small {
		max-width: rem(952px);
	}
}
