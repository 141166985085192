﻿.languages__checkbox {
	display: none;

	&:checked + .languages {

		dt {
			border-radius: $border-radius $border-radius 0 0;
		}

		dd {
			display: block;
		}

		label {

			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}

	&:checked + %languages-header-menu {

		dt {
			background: #fff;
		}
	}
}

%languages-side-menu,
%languages-header-menu {
	@include font-size-button;
	font-weight: 600;
	color: $color-primary;

	dt,
	dd {
		background: #fff;
	}

	dd {
		display: none;
		position: absolute;
		top: 100%;
	}

	label {
		cursor: pointer;
	}

	label,
	li a {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: rem(24px);
			height: rem(15px);
			top: rem(21px);
			left: rem(24px);
			background: url(../../images/languages.svg) no-repeat;
			background-size: rem(24px) rem(90px);
			background-position-y: rem(-30px);
			overflow: hidden;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	a {
		display: block;
		padding: rem(9px) rem(18px) rem(8px) rem(65px);
		text-decoration: none;
		transition: background $transition-time ease-in-out;
		border: none;

		&:hover,
		&:focus {
			background: rgba($color-primary, 0.15);
		}
	}

	.is-nl {

		&:before {
			background-position-y: 0;
		}
	}

	.is-nl-be {

		&:before {
			background-position-y: rem(-60px);
		}
	}

	.is-pl {

		&:before {
			background-position-y: rem(-45px);
		}
	}

	.is-de {

		&:before {
			background-position-y: rem(-15px);
		}
	}

	.is-fr {

		&:before {
			background-position-y: rem(-75px);
		}
	}
}

%languages-header-menu {
	display: none;

	@include breakpoint(l) {
		display: block;
	}

	dt {
		border-radius: $border-radius $border-radius 0 0;
		width: rem(68px);
		height: rem(58px);
		overflow: hidden;
		background: none;

		label {
			display: block;
			width: 100%;
			height: 100%;

			&:before {
				width: rem(30px);
				height: rem(20px);
				top: rem(19px);
				left: rem(18px);
				background-size: rem(30px) rem(114px);
				background-position-x: rem(-1px);
				background-position-y: rem(-38px);
				border: 1px solid rgba(#fff, 0.6);
				border-radius: rem(4px);
			}

			&.is-nl {

				&:before {
					background-position-y: 0;
				}
			}

			&.is-nl-be {

				&:before {
					background-position-y: rem(-76px);
				}
			}

			&.is-pl {

				&:before {
					background-position-y: rem(-57px);
				}
			}

			&.is-de {

				&:before {
					background-position-y: rem(-19px);
				}
			}

			&.is-fr {

				&:before {
					background-position-y: rem(-96px);
				}
			}
		}

		span {
			visibility: hidden;
		}
	}

	dd {
		border-radius: 0 $border-radius $border-radius $border-radius;
		padding: rem(9px) 0;
	}

	label,
	li a {

		&:before {
			top: rem(12px);
		}
	}
}

%languages-side-menu {
	position: relative;
	z-index: 2;
	cursor: pointer;

	dt {
		border-radius: $border-radius;
		overflow: hidden;
	}

	label {
		display: block;
		padding: rem(19px) rem(20px) rem(18px) rem(72px);
		cursor: pointer;
		transition: background $transition-time ease-in-out;

		&::after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 50%;
			right: rem(24px);
			transform: translateY(-50%);
			transform-origin: center;
			font-size: rem(16px);
			color: $color-primary;
		}

		&:hover,
		&:focus {
			background: rgba($color-primary, 0.15);
		}
	}

	dd {
		width: 100%;
		padding: 0;
		border-radius: 0;
		margin: 0;
		left: 0;
		border-radius: 0 0 $border-radius $border-radius;
		overflow: hidden;
	}

	li:last-child a {
		border: none;
	}

	a {
		padding: rem(19px) rem(20px) rem(18px) rem(72px);
	}

	label,
	li a {

		&:before {
			top: rem(21px);
		}
	}
}
