﻿dl.list {
	display: flex;
	flex-wrap: wrap;
	margin: 0;

	@include breakpoint(t) {
		padding-right: rem(40px);
	}

	dt,
	dd {
		width: 100%;
		margin: 0;

		@include breakpoint(t) {
			float: left;
		}
	}

	dt {
		&:first-of-type {
			padding-top: rem(10px);
			border-top: rem(1px) solid transparentize($color-primary, 0.8);

			@include breakpoint(t) {
				padding-top: rem(20px);
				padding-bottom: rem(20px);
				margin-bottom: rem(20px);
			}
		}

		@include breakpoint(t) {
			width: (100% / 3);
			padding-bottom: rem(20px);
			border-bottom: rem(1px) solid transparentize($color-primary, 0.8);
			margin-bottom: rem(20px);
		}
	}

	dd {
		padding-bottom: rem(10px);
		border-bottom: rem(1px) solid transparentize($color-primary, 0.8);
		margin-bottom: rem(10px);

		@include breakpoint(t) {
			padding-bottom: rem(20px);
			margin-bottom: rem(20px);
		}

		&:last-of-type {
			//margin-bottom: 0;
		}

		@include breakpoint(t) {
			width: (100% / 3 * 2);

			&:first-of-type {
				padding-top: rem(20px);
				border-top: rem(1px) solid transparentize($color-primary, 0.8);
			}
		}

		a {
			color: $color-primary;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
