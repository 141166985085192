﻿.input {
	width: 100%;
	min-height: rem(56px);
	padding: 0 rem(20px);
	overflow: hidden;
	border: 0;
	transition: all $transition-time ease-in-out;
	line-height: rem(56px);
	border: 1px solid transparent;
	border-radius: $border-radius;
	box-shadow: none;
	outline: none;

	&:hover,
	&:focus {
		border: 1px solid $color-primary;
		box-shadow: none;
		outline: none;
	}
}
