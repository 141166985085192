﻿.legal {
	@include font-legal;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: rem(9px) 0 rem(11px);
	margin: 0 rem(-16px) 0;
	list-style: none;
	color: #fff;

	li {
		padding: rem(5px) rem(16px);
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}
}
