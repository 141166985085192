﻿.columns.has-gutter-48.shortcuts {
	margin-bottom: rem(4px);

	.columns {
	}
}

.shortcut {
	position: relative;
	display: block;

	.button {
		position: absolute;
		width: calc(100% - 2.5rem); // 40px
		top: rem(20px);
		left: rem(20px);

		@include breakpoint(l) {
			width: calc(100% - 5rem); // 80px
			top: rem(40px);
			left: rem(40px);
		}
	}

	&[target=_blank] {

		.button {
			@extend %button-is-green;
			@extend %button-is-external;
		}
	}

	img {
		transform: scale(1);
		transition: transform 500ms ease;
	}

	&:focus,
	&:hover {

		img {
			transform: scale(1.1);
		}
	}
}