﻿.flippers {
	position: fixed;
	z-index: 2;
	top: rem(250px);
	right: 0;
	pointer-events: none;

	.flipper {
		position: relative;
		z-index: 1;
		display: block;
		padding: rem(12px) rem(10px) rem(12px) rem(66px);
		transform: translateX(calc(100% - 3.5rem - 4px));
		transition: transform $transition-time ease-in-out;
		overflow: hidden;
		pointer-events: initial;

		&:first-child {
			border-radius: $border-radius 0 0 0;
		}

		&:last-child {
			border-radius: 0 0 0 $border-radius;
		}

		&:before {
			position: absolute;
			content: '';
			width: rem(56px);
			height: rem(56px);
			padding: rem(16px) 0 0 rem(18px);
			top: 0;
			left: 0;
			font-size: rem(24px);
		}

		&.is-whatsapp {
			background: #004aa3;
			color: #fff;

			&:before {
				@include icon($icon-whatsapp);
				background: #004aa3;
			}
		}

		&.is-telephone {
			background: $color-primary;
			color: #fff;

			&:before {
				@include icon($icon-phone);
				background: $color-primary;
			}
		}

		&.is-mail {
			background: #fff;
			color: $color-primary;

			&:before {
				@include icon($icon-mail);
				background: #fff;
			}
		}

		&:hover {
			transform: translateX(0);
		}
	}
}
