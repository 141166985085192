﻿.overview-navigation {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: rem(12px);

	.overview-navigation__next,
	.overview-navigation__previous {
		position: relative;
		color: $color-primary;
		font-weight: 600;

		&:after {
			position: absolute;
			top: rem(10px);
			font-size: rem(12px);
			transition: transform $transition-time ease-in;
		}

		&.is-disabled {
			visibility: hidden;
		}
	}

	.overview-navigation__previous {
		margin-right: auto;
		padding: 0 0 0 rem(14px);

		&:after {
			@include icon($icon-chevron-left);
			left: 0;
		}

		&:hover:after {
			transform: translateX(-5px);
		}
	}

	.overview-navigation__next {
		margin-left: auto;
		padding: 0 rem(14px) 0 0;

		&:after {
			@include icon($icon-chevron-right);
			right: 0;
		}

		&:hover:after {
			transform: translateX(5px);
		}
	}

	.overview-navigation__total {
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	.dropdown__checkbox {
		display: none;

		&:checked + .dropdown {

			dt label {
				border-bottom-color: #fff;
			}

			dd {
				display: block;
			}
		}
	}

	.dropdown {
		position: relative;
		z-index: 10;
		margin: 0 rem(16px);

		dt {
		}

		label {
			display: block;
			border: 1px solid $color-border;
			font-weight: bold;
			padding: rem(11px) rem(16px);
			text-align: center;
			cursor: pointer;

			&:after {
				@include icon($icon-chevron-down);
				position: relative;
				font-size: rem(12px);
				left: rem(4px);
			}
		}

		dd {
			display: none;
			position: absolute;
			width: 100%;
			top: 100%;
			background: #fff;
			border: 1px solid $color-border;
			border-top: none;

			a {
				display: block;
				text-align: center;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
