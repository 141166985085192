﻿.title {
	@include font-size-title;
	font-weight: 800;

	span {
		display: block;
	}

	strong {
		font-weight: 800;
	}

	&.is-small {
		@include font-size-title-small;
	}
}

.sub-title {
	@include font-size-title-small;
	color: $color-primary-dark;
	font-weight: 800;
}