﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 16px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $color-primary;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	@include font-size-button;
	position: fixed;
	z-index: 0;
	z-index: 1001;
	height: rem(56px);
	padding: rem(19px) rem(89px) 0 rem(25px);
	border-radius: $border-radius;
	border: none;
	color: #fff;
	transition: opacity $transition-time ease-in-out, background $transition-time ease-in-out, color $transition-time ease-in-out;
	background: #004aa3;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	overflow: hidden;

	&:before {
		position: absolute;
		z-index: -2;
		content: '';
		width: rem(64px);
		height: 100%;
		top: 0;
		right: 0;
		background: #fff;
	}

	&:after {
		position: absolute;
		z-index: -1;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.2;
		background: #000;
		transform: translateX(-100%);
		transition: transform 150ms ease-in-out;
	}

	.hamburger-box {
		position: absolute;
		top: rem(21px);
		right: rem(24px);
	}

	.hamburger-label {
		transition: opacity $transition-time ease-in-out
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover,
	&:focus {

		&:after {
			transform: translateX(0);
		}
	}

	%hamburger-hover {

		.hamburger-label {
			opacity: 0;
		}

		&:after {
			transform: translateX(0);
		}
	}
}
