﻿.swiper-pagination {

}

%swiper-flat-pagination {
	margin: rem(-5px);

	.swiper-pagination-bullet {
		width: rem(40px);
		height: rem(4px);
		background: $color-grey-dark;
		opacity: 0.3;
		border-radius: 0;
		margin: rem(5px);
		transition: opacity $transition-time ease;

		&:hover {
			opacity: 0.8;
		}

		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}

%swiper-numberd-pagination {
	margin: 0 rem(-5px);

	.swiper-pagination-bullet {
		background: #fff;
		width: rem(72px);
		height: rem(72px);
		font-size: rem(36px);
		font-weight: 800;
		border: 2px solid transparent;
		border-radius: 50%;
		padding: rem(17px) 0 0;
		margin: rem(5px) rem(17px);
		transition: all $transition-time ease;
		opacity: 1;
		background: transparent;
		text-align: center;
		color: rgba(#fff, 0.5);

		&:hover {
			border-color: #fff;
			color: #fff;
		}

		&.swiper-pagination-bullet-active {
			border-color: #fff;
			color: #fff;
		}
	}

	&.is-vertical {
		display: flex;
		flex-wrap: wrap;
		width: rem(12px);

		.swiper-pagination-bullet {
			margin: rem(5px) 0;
		}
	}
}
