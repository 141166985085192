﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-2by3 {
		@include responsive-container-psudo(2, 3);
	}

	&.is-16by9 {
		@include responsive-container-psudo(16, 9);
	}

	img {
		width: 100%;
		height: auto;
	}

	&.has-placeholder {
		background: #fff url(../../images/logo-kerklaan-greenhouses-small.svg) center no-repeat;
		background-size: rem(102px) rem(89px);
	}

	&.video {
		position: relative;
		overflow: hidden;

		a {
			&::before,
			&::after {
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&::before {
				display: block;
				width: 4rem;
				height: 4rem;
				background: #fff;
				border-radius: 2rem;
				content: '';
				text-align: center;

				@include breakpoint(t) {
					width: 6rem;
					height: 6rem;
					border-radius: 3rem;
				}
			}

			&::after {
				@include icon($icon-play);
				padding: 0.063rem 0 0 0.563rem;
				color: $color-primary;
				font-size: 2rem;
				transition: all 150ms ease-in-out;

				@include breakpoint(t) {
					font-size: 3rem;
				}
			}

			&:hover::after,
			&:focus::after {
				color: $color-green;
			}
		}

		&.video-small {
			a {
				&::before {
					width: 2rem;
					height: 2rem;

					@include breakpoint(t) {
						width: 4rem;
						height: 4rem;
					}
				}

				&::after {
					font-size: 1rem;

					@include breakpoint(t) {
						font-size: 2rem;
						padding-left: 0.375rem;
					}
				}
			}
		}
	}
}
