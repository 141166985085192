﻿.breadcrumbs__container {
	background: $color-grey;

	+ .content-block {
		padding-top: rem(34px);
	}

	.is-demolition & {
		background: rgba($color-yellow, 0.1);

	}
}

.breadcrumbs {
	//@extend %container;
	list-style: none;
	width: 100%;
	color: $color-grey-dark;
	display: flex;
	flex-direction: row;
	white-space: nowrap;

	.is-demolition & {
		color: #000;
	}

	li {
		position: relative;
		padding: rem(13px) 0;

		> a {
			font-weight: 600;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		+ li {
			padding-left: 1.75rem;


			&::before {
				@include icon($icon-chevron-right);
				position: absolute;
				top: rem(26px);
				left: rem(10px);
				font-size: 0.5rem;
			}
		}

		&:last-of-type {
			overflow: hidden;
			text-overflow: ellipsis;

			> a {
				font-weight: 400;
			}
		}
	}
}

	
