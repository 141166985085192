﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/effect-fade/effect-fade';

.swiper-container {

	.swiper-slide {
		height: auto;
	}

	&.has-overflow {
		overflow: visible;
	}
}