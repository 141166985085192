﻿.nav.is-side-nav {
	@include font-size-menu;
	font-weight: 800;

	> ul {
		padding: rem(40px) 0;
	}

	> ul > li:first-child > a {
		border-top: 1px solid rgba(#fff, 0.1);
	}

	li {

		a {
			transition: background $transition-time ease-in;

			&:hover {
				background: rgba(#000, 0.1);
			}
		}

		&:not(.has-children) {

			a {

				&:after {
					@include icon($icon-arrow-right);
					position: absolute;
					font-size: rem(24px);
					top: rem(24px);
					right: rem(22px);
					transition: right $transition-time ease-in;
				}

				&:hover {
					background: rgba(#000, 0.1);

					&:after {
					}
				}
			}
		}

		&.has-children {
			position: relative;

			> label {
				position: absolute;
				z-index: 1;
				width: rem(68px);
				height: rem(71px);
				top: 1px;
				right: 0;
				text-indent: -99999px;
				background: $color-primary;
				transition: background $transition-time ease-in;
				cursor: pointer;

				&:after {
					@include icon($icon-arrow-down);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: rem(24px);
					text-indent: 0;
				}

				&:hover {
					background: rgba(#000, 0.1);
				}
			}

			> input {
				display: none;

				&:checked ~ ul {
					display: block;
				}
			}

			li:not(.has-children) a {

				&:after {
					top: rem(19px);
					font-size: rem(18px);
				}
			}
		}

		> ul {
			list-style: none;
			padding: 0;
			display: none;

			a {
				@include font-size-title-small;
				padding: rem(12px) rem(68px) rem(12px) rem(44px);
				font-weight: 600;
				/*&:after {
					font-size: rem(18px) !important;
				}*/
			}
		}
	}

	a {
		position: relative;
		display: block;
		z-index: 1;
		position: relative;
		padding: rem(22px) rem(68px) rem(22px) rem(22px);
		transition: color $transition-time ease-in-out;
		border-bottom: 1px solid rgba(#fff, 0.1);
	}
}
