﻿.arrow-list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {

		&:first-child a {
			border-top: 1px solid rgba(#fff, 0.1);
		}

		a {
			position: relative;
			display: block;
			border-bottom: 1px solid rgba(#fff, 0.1);
			padding: rem(8px) rem(48px) rem(8px) rem(16px);
			text-decoration: none;
			transition: color $transition-time ease-in-out;

			&::after {
				@include icon($icon-arrow-right);
				position: absolute;
				top: 50%;
				right: rem(16px);
				transform: translateY(-50%);
			}

			&:hover,
			&:focus {
				color: $color-green;
			}
		}
	}
}
