﻿.to-top {
	position: relative;
	z-index: 0;
	display: block;
	width: rem(56px);
	height: rem(56px);
	border-radius: 0 0 $border-radius $border-radius;
	background: #fff;
	text-indent: -99999px;
	color: $color-primary;
	overflow: hidden;
	transition: background $transition-time ease-in;

	&:before {
		position: absolute;
		z-index: -1;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.2;
		background: #000;
		transform: translateY(100%);
		transition: transform 150ms ease-in-out;
	}

	&:after {
		@include icon($icon-arrow-up);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(24px);
		text-indent: 0;
		transition: transform $transition-time ease-in;
	}

	&:hover {

		&:before {
			transform: translateY(0);
		}
	}
}
