﻿.logo {
	display: block;
	background-size: contain;
	overflow: hidden;
	text-indent: -99999px;

	&.is-brand {
		width: rem(244px);
		height: rem(48px);
		background-image: url(../../images/logo-kerklaan-greenhouses.svg);
		transition: opacity $transition-time ease-in-out;

		&.is-alt {
			width: rem(312px);
			height: rem(167px);
			background-image: url(../../images/logo-kerklaan-greenhouses-alt.svg);
		}
	}
}
