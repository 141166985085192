﻿.content {
	overflow: hidden;

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	p,
	ol,
	table {
		margin: 0 0 rem(30px);
	}

	.sub-title {
		margin: 0;
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			position: relative;
			border-top: 1px solid $color-border;
			padding: rem(11px) rem(20px) rem(12px) rem(20px);

			&:before {
				position: absolute;
				content: '';
				width: rem(4px);
				height: rem(4px);
				border-radius: 50%;
				top: rem(25px);
				left: 0;
				background: $color-text;
			}

			&:last-child {
				border-bottom: 1px solid $color-border;
			}
		}
	}
}
